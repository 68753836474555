import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col, Form } from "react-bootstrap"
import elephant from "../../images/topic-3/section-2/baby-elephant.jpg"
import ButtonLink from "../../components/buttonLink"
import BottomNav from "../../components/bottomNav"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import MyBreadcrumb from "../../components/myBreadcrumb"
import MyModal from "../../components/mathModal"
import RespMsg from "../../components/respMsg"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const EPopGrowthSection2 = ({ location }) => {
  const { t } = useTranslation()

  const pageContent = {
    breadcrumb: [
      parse(t("Topic3_Section2_breadcrumb[1][0]")),
      parse(t("Topic3_Section2_breadcrumb[1][1]")),
      parse(t("Topic3_Section2_breadcrumb[1][2]")),
      parse(t("Topic3_Section2_breadcrumb[1][3]")),
    ],
    title: [
      parse(t("Topic3_Section2_title[0]")),
      parse(t("Topic3_Section2_title[1]")),
      parse(t("Topic3_Section2_title[2]")),
      parse(t("Topic3_Section2_title[3]")),
    ],
    topText: [parse(t("Topic3_Section2_topText[0]")), "top2", "top3", "top4"],
    labels: [
      [
        parse(t("Topic3_Section2_labels[0][0]")),
        parse(t("Topic3_Section2_labels[0][1]")),
      ],
      [
        parse(t("Topic3_Section2_labels[1][0]")),
        parse(t("Topic3_Section2_labels[1][1]")),
      ],
      ["", ""],
      ["", ""],
    ],
    imageText: [parse(t("Topic3_Section2_imgCaption")), "", "", ""],
    bullets: [
      parse(t("Topic3_Section2_bullets[0]")),
      parse(t("Topic3_Section2_bullets[1]")),
    ],
    bottomText: parse(t("Topic3_Section2_bottomText")),
    instructions: [
      <span>
        {parse(t("Topic3_Section2_instructions[0]"))}
        <span className="linkMeNoColor" onClick={() => setModalShow(true)}>
          {parse(t("Topic3_Section2_instructions.here"))}
        </span>{" "}
        {parse(t("Topic3_Section2_instructions.ifyouneedhelp"))}
      </span>,
      <span>
        {parse(t("Topic3_Section2_instructions[1]"))}
        <span className="linkMeNoColor" onClick={() => setModalShow(true)}>
          {parse(t("Topic3_Section2_instructions.here"))}
        </span>{" "}
        {parse(t("Topic3_Section2_instructions.ifyouneedhelp"))}
      </span>,
      parse(t("Topic3_Section2_instructions[2]")),
      parse(t("Topic3_Section2_instructions[3]")),
    ],
    answers: [
      [1.0, 2],
      [0.92, 85],
      [0.84, 151],
      [0.71, 230],
      [0.58, 275],
      [0.5, 282],
      [0.36, 259],
      [0, 0],
    ],
    msgs: {
      errorMsg: [
        <span>
          {parse(t("Topic3_Section2_msgs.errorMsg[0]"))}
          <span className="linkMeNoColor" onClick={() => setModalShow(true)}>
            {parse(t("Topic3_Section2_instructions.here"))}
          </span>{" "}
          {parse(t("Topic3_Section2_instructions.ifyouneedhelp"))}
        </span>,
        <span>
          {parse(t("Topic3_Section2_msgs.errorMsg[1]"))}
          <span className="linkMeNoColor" onClick={() => setModalShow(true)}>
            {parse(t("Topic3_Section2_instructions.here"))}
          </span>{" "}
          {parse(t("Topic3_Section2_instructions.ifyouneedhelp"))}
        </span>,
        "",
        "",
        "",
        "",
        "",
      ],

      successMsg: [
        parse(t("Topic3_Section2_msgs.successMsg[0]")),
        parse(t("Topic3_Section2_msgs.successMsg[1]")),
        "",
        "",
        "",
        "",
        "",
      ],
      finalErrorMsg: [
        parse(t("Topic3_Section2_msgs.finalErrorMsg[0]")),
        parse(t("Topic3_Section2_msgs.finalErrorMsg[1]")),
        "",
        "",
        "",
        "",
        "",
      ],
    },
    table: {
      headers: [
        parse(t("Topic3_Section2_table.headers[0]")),
        parse(t("Topic3_Section2_table.headers[1]")),
        parse(t("Topic3_Section2_table.headers[2]")),
        parse(t("Topic3_Section2_table.headers[3]")),
        parse(t("Topic3_Section2_table.headers[4]")),
      ],
      data: [
        ["1", "1905", "10", "-", "-"],
        ["2", "1930", "613", "-", "-"],
        ["", "1935", "1189", "-", "-"],
        ["", "1940", "2139", "-", "-"],
        ["3", "1944", "3157", "-", "-"],
        ["", "1946", "3750", "-", "-"],
        ["", "1950", "4810", "-", "-"],
        ["4", "1996", "7500", "-", "-"],
      ],
    },
  }
  // ============================================================================================
  const [part, setPart] = useState(0)
  const [section, setSection] = useState(0)
  const [errorCount, setErrorCount] = useState(0)
  const [msgType, setMsgType] = useState("")
  const [msgContent, setMsgContent] = useState("")
  const [showMsg, setShowMsg] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [answer, setAnswer] = useState(0)
  const [answer2, setAnswer2] = useState(0)
  const [currentYear, setCurrentYear] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [resetPlaceholder, setResetPlaceholder] = useState(false)
  const [resetPlaceholder2, setResetPlaceholder2] = useState(false)

  // ============================================================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic3_Section2_pageTitle"))} />
      {/* <MyBreadcrumb topicTitle="Topic 1: Exponential Growth" topicLink="" /> */}
      <MyBreadcrumb
        topicLink="epopulationgrowth"
        topicTitle={parse(t("Topic3_Section2_breadcrumb[0]"))}
        currentTitle={pageContent.breadcrumb[part]}
      />

      <div className="homeContent">
        <Row>
          <Col sm="12">
            <TopHeader
              title={pageContent.title[part]}
              content={pageContent.topText[section]}
            />
          </Col>
        </Row>

        <div>
          <Row hidden={part == 2}>
            <Col sm="12" md="12">
              <Instructions content={pageContent.instructions[part]} />
            </Col>
          </Row>
          <Row>
            <Col sm="6" hidden={part == 2}>
              {/* FIRST PART */}
              <div hidden={part != 0}>
                <label>{pageContent.labels[part][0]}</label>{" "}
                <Form.Control
                  placeholder="1-(N/K)"
                  disabled={errorCount >= 4}
                  onChange={e => setAnswer(e.target.value)}
                  type="number"
                  value={resetPlaceholder == true ? "" : null}
                  // minlength="9"
                  // maxlength="11"
                />
                <br />
                <label>{pageContent.labels[part][1]}</label>{" "}
                <Form.Control
                  placeholder="dN/dt"
                  disabled={errorCount >= 4}
                  onChange={e => setAnswer2(e.target.value)}
                  type="number"
                  value={resetPlaceholder == true ? "" : null}
                  // minlength="9"
                  // maxlength="11"
                />{" "}
                <br />
                <RespMsg
                  type={msgType}
                  content={msgContent}
                  hidden={!showMsg}
                />
                <ButtonLink
                  // btnLink="#top"
                  btnWidth
                  variant="info"
                  className="btnNext"
                  btnText={parse(t("Submit"))}
                  disabled={(errorCount >= 4) | nextBtnEnabled}
                  onClick={() => {
                    if (
                      (answer != pageContent.answers[section][0]) |
                      (answer2 != pageContent.answers[section][1])
                    ) {
                      setMsgContent(pageContent.msgs.errorMsg[section])
                      setMsgType("error")
                      setErrorCount(errorCount + 1)
                    } else {
                      setMsgContent(pageContent.msgs.successMsg[section])
                      setMsgType("correct")
                      setNextBtnEnabled(true)
                    }
                    if (
                      errorCount >= 3 &&
                      (answer != pageContent.answers[section][0]) |
                        (answer2 != pageContent.answers[section][1])
                    ) {
                      setMsgContent(pageContent.msgs.finalErrorMsg[section])
                      setMsgType("error")
                      setNextBtnEnabled(true)
                    }
                    setShowMsg(true)
                  }}
                  // btnColor="#4A331C"
                />
              </div>
              {/* SECOND PART */}
              <div hidden={part != 1}>
                <label>{pageContent.labels[part][0]}</label>{" "}
                <label>{pageContent.labels[part][1]}</label>{" "}
                <Form.Control
                  placeholder="rmax[1-(N/K)]"
                  disabled={errorCount >= 4}
                  onChange={e => setAnswer(e.target.value)}
                  type="number"
                  value={resetPlaceholder2 == true ? "" : null}
                  // minlength="9"
                  // maxlength="11"
                />
                <br />
                <RespMsg
                  type={msgType}
                  content={msgContent}
                  hidden={!showMsg}
                />
                <ButtonLink
                  // btnLink="#top"
                  btnWidth
                  variant="info"
                  className="btnNext"
                  btnText={parse(t("Submit"))}
                  disabled={(errorCount >= 4) | nextBtnEnabled}
                  onClick={() => {
                    if (answer != 0.15) {
                      setMsgContent(pageContent.msgs.errorMsg[part])
                      setMsgType("error")
                      setErrorCount(errorCount + 1)
                    } else {
                      setMsgContent(pageContent.msgs.successMsg[part])
                      setMsgType("correct")
                      setNextBtnEnabled(true)
                    }
                    if (errorCount >= 3 && answer != 0.15) {
                      setMsgContent(pageContent.msgs.finalErrorMsg[part])
                      setMsgType("error")
                      setNextBtnEnabled(true)
                    }
                    setShowMsg(true)
                  }}
                  // btnColor="#4A331C"
                />
              </div>
            </Col>
            <Col sm="8" hidden={part != 2}>
              <br />
              {pageContent.bottomText}
            </Col>
            <Col sm="4" hidden={part != 2}>
              <img src={elephant} alt={parse(t("Topic3_Section2_imgAlt"))} />
            </Col>
            <Col sm="6" hidden={part == 2}>
              {" "}
              <table>
                <tr className="rightChilds">
                  <th>{pageContent.table.headers[0]}</th>
                  <th>{pageContent.table.headers[1]}</th>
                  <th>{pageContent.table.headers[2]}</th>
                  <th>{pageContent.table.headers[3]}</th>
                  <th>{pageContent.table.headers[4]}</th>
                </tr>
                <tr
                  className={
                    currentYear == 0 && part == 0
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[0][0]}</td>
                  <td>{pageContent.table.data[0][1]}</td>
                  <td>{pageContent.table.data[0][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 0) | (part > 0)
                      ? pageContent.answers[0][0].toFixed(2)
                      : pageContent.table.data[0][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 0) | (part > 0)
                      ? pageContent.answers[0][1]
                      : pageContent.table.data[0][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 1 && section == 0
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[1][0]}</td>
                  <td>{pageContent.table.data[1][1]}</td>
                  <td>{pageContent.table.data[1][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 1) | (part > 1)
                      ? pageContent.answers[1][0].toFixed(2)
                      : pageContent.table.data[1][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 1) | (part > 1)
                      ? pageContent.answers[1][1]
                      : pageContent.table.data[1][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 2 && section == 0
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[2][0]}</td>
                  <td>{pageContent.table.data[2][1]}</td>
                  <td>{pageContent.table.data[2][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 2) | (part > 2)
                      ? pageContent.answers[2][0].toFixed(2)
                      : pageContent.table.data[2][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 2) | (part > 2)
                      ? pageContent.answers[2][1]
                      : pageContent.table.data[2][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 3 && section == 0
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[3][0]}</td>
                  <td>{pageContent.table.data[3][1]}</td>
                  <td>{pageContent.table.data[3][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 3) | (part > 3)
                      ? pageContent.answers[3][0].toFixed(2)
                      : pageContent.table.data[3][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 3) | (part > 3)
                      ? pageContent.answers[3][1]
                      : pageContent.table.data[3][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 4 && section == 0
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[4][0]}</td>
                  <td>{pageContent.table.data[4][1]}</td>
                  <td>{pageContent.table.data[4][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 4) | (part > 4)
                      ? pageContent.answers[4][0].toFixed(2)
                      : pageContent.table.data[4][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 4) | (part > 4)
                      ? pageContent.answers[4][1]
                      : pageContent.table.data[4][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 5 && section == 0
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[5][0]}</td>
                  <td>{pageContent.table.data[5][1]}</td>
                  <td>{pageContent.table.data[5][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 5) | (part > 5)
                      ? pageContent.answers[5][0].toFixed(2)
                      : pageContent.table.data[5][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 5) | (part > 5)
                      ? pageContent.answers[5][1]
                      : pageContent.table.data[5][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 6 && section == 0
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[6][0]}</td>
                  <td>{pageContent.table.data[6][1]}</td>
                  <td>{pageContent.table.data[6][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 6) | (part > 6)
                      ? pageContent.answers[6][0].toFixed(2)
                      : pageContent.table.data[6][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 6) | (part > 6)
                      ? pageContent.answers[6][1]
                      : pageContent.table.data[6][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 7 && section == 0
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[7][0]}</td>
                  <td>{pageContent.table.data[7][1]}</td>
                  <td>{pageContent.table.data[7][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 7) | (part > 7)
                      ? pageContent.answers[7][0].toFixed(2)
                      : pageContent.table.data[7][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 7) | (part > 7)
                      ? pageContent.answers[7][1]
                      : pageContent.table.data[7][4]}
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
          <br />
        </div>
        <hr />

        <MyModal
          size="lg"
          className="rModal"
          show={modalShow}
          onHide={() => setModalShow(false)}
          centered
        />

        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              prevName={parse(t("Previous"))}
              btnLinkPrev={
                part == 0 ? "/epopulationgrowth/section1" : "#myHeader"
              }
              onClickPrev={() => {
                setMsgType("")
                setMsgContent("")
                setShowMsg(false)
                setErrorCount(0)
                switch (part) {
                  case 1:
                    setNextBtnEnabled(false)
                    setPart(part - 1)
                    setResetPlaceholder(false)
                    setResetPlaceholder2(true)
                  case 2:
                    setNextBtnEnabled(false)
                    setPart(part - 1)
                  default:
                    return null
                }
              }}
              btnLinkNext={
                part == 2 ? "/epopulationgrowth/section3" : "#myHeader"
              }
              nextName={parse(t("Next"))}
              onClickNext={() => {
                setPart(part + 1)
                setMsgType("")
                setMsgContent("")
                setShowMsg(false)
                setErrorCount(0)
                switch (part) {
                  case 0:
                    setResetPlaceholder(true)
                    setResetPlaceholder2(false)
                    setNextBtnEnabled(false)
                    break
                  case 1:
                    setResetPlaceholder2(false)
                    setNextBtnEnabled(true)
                    break
                  case 2:
                    break
                  default:
                    return null
                    break
                }
              }}
              disabled={!nextBtnEnabled}
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default EPopGrowthSection2
